body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
}
