/* app.css */
.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.topbar {
  background-color: #ffffff;
  box-shadow: none;
  padding: 20px 0;
  position: relative;
}

.topbar:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  box-shadow: inset 0 -1px 0 0 #DFE5F1;
}

.topbar .logo {
  height: 50px;
  margin-right: 15px;
  cursor: pointer;
}

.topbar .title {
  cursor: pointer;
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 700;
  color: #333;
}

.topbar .links .link {
  color: #8C8C8C;
  font-size: 18px;
  font-family: 'SF Pro Display', sans-serif;
  padding: 0 18px;
  text-transform: none;
  text-decoration: none;
}

.topbar .links .link:hover {
  text-decoration: none;
}

.topbar .buttons .signup-button {
  background-color: #01AC1C;
  color: #fff;
  font-size: 18px;
  font-family: 'SF Pro Display', sans-serif;
  padding: 0 18px;
  text-transform: none;
  border-radius: 30px;
  border: none;
  height: 40px;
}

.topbar .buttons .login-button {
  color: #8C8C8C;
  font-size: 18px;
  font-family: 'SF Pro Display', sans-serif;
  padding: 0 18px;
  text-transform: none;
  border: 1px solid rgba(140, 140, 140, 0.35);
  border-radius: 30px;
  margin-right: 18px;
  height: 36px;
}

.topbar .buttons .button:hover {
  background-color: transparent;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.marquee-content {
  display: flex;
  width: fit-content;
  animation: marquee 20s linear infinite;
}

.text-item {
  height: 50px;
  display: flex;
  align-items: center;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
